import React, { Component} from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./card.css";
import "./cards2.css";
import Blog from './blog';
import img3 from '../assets/ftiger/wag.jpeg';
import FooterPage from './footerv2';
import { Helmet } from 'react-helmet';

const TITLE = 'SauronEye | Autonomous Greenhouse Challenge by Wageningen University';

const Blog2 = props =>{
    return(
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
        <div>

        <div>
         <Blog 
         title="Autonomous Greenhouse Challenge by Wageningen University"
          imgsrc={img3}
            text="Since years Wageningen University & Research is working on autonomous greenhouses, climate control, crop modelling, use of sensors, data, intelligence, computer vision and robotics. We develop new technology and new algorithms for and together with industry. You will find our solutions in many products in the sector already, maybe without recognizing it. We conduct research to understand crop physiology and product quality, we develop knowledge on climate control and plant phenotyping and are on the frontline of agrorobotics research to make new technologies useful.

In 2021/22 we will organise the 3rd International Challenge to sustainably grow vegetables in greenhouses by different international teams via fully automated control with the use of AI. This year’s crop will be lettuce. And this time, it must be fully autonomous!

A first and second edition of the Autonomous Greenhouse Challenge have been carried out at Wageningen University & Research, The Netherlands, in 2018-2020, sponsored by Tencent and David Wallerstein (CXO Tencent). During these editions we have shown that artificial intelligence can potentially be superior to human intelligence, hence can potentially control indoor farming in the future. The goal of the third challenge will be fully automated control."/>
            
         </div>
        </div>

        <div className='py-0' >
        <FooterPage/>
        </div>

            </React.StrictMode>
    );
}  

export default Blog2;