import React, { Component, useEffect, useState } from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./responsive.css";
import './bootstrap.css';
import Cards3 from './cards3';
import Cards4 from './cards4';
import img2 from '../assets/TopBG.png';
import img3 from '../assets/ftiger/GP3.jpeg';
import img4 from '../assets/ftiger/GP2.jpeg';
import img5 from '../assets/ftiger/GP4.jpeg';
import img6 from '../assets/ftiger/TG1.jpeg';
import img9 from '../assets/ftiger/TG8.jpeg';
import img10 from '../assets/ftiger/GP44.jpeg';
import img11 from '../assets/ftiger/GP66.jpeg';
import img12 from '../assets/ftiger/GP37.jpeg';
import img13 from '../assets/ftiger/GP35.jpeg';
import img14 from '../assets/ftiger/GP13.jpeg';

function SimpleNav() {

  const [data, setData] = useState([]);
  useEffect(() => {
    fetch('/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
     {
        return (
<div class = "displayBlock width100">

<div class = "px-0 py-0 " >

<Navbar collapseOnSelect expand="lg" bg='white' variant="light" class="row" width="100%">
  <div class="collapse navbar-collapse col-sm-12  my-lg-0" id="navbarSupportedContent" >

    <Link to = '' class="navbar-brand" >
    <img src={searchLogo}
          width="180"
          height="35"
          alt="">
    </img>
    </Link>

    <Link to = '' class="navbar-brand col-sm-2" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '          ' class="navbar-brand col-sm-3" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '/Blog' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Blog</text>
    </Link>

    <Link to = '/Careers' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Careers</text>
    </Link>

    <a  href="https://2qdn8k34pw2.typeform.com/to/m9bsQPaZ" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Customers</text>
    </a>

    <a  href="https://2qdn8k34pw2.typeform.com/to/hs3lwHbp" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Investors </text>
    </a>

</div>

</Navbar>

<div className="MidTextDiv2">
<img className="MidImage1" src={img6}/>
</div>

<div className="TopDiv col">
  <div>
  <text className="TopTextBold" > Generative AI Copilot for Climate Change<br></br> Risk Assessment and Mitigation Strategies<br></br>
  </text>
  </div>
  <div>
  <text className="UnderTextBold" > Actionable fresh insights to detect risks of "climate change hazards" and "new climate regulations"<br></br> on your operations and customer portfolio, and analytics to mitigate and manage these risks<br></br> based on operations, active regions, and exposure level to climate threats
  </text>
  </div>
</div>

<div className="MidButDiv">
<a  href="https://2qdn8k34pw2.typeform.com/to/m9bsQPaZ" class="" >
<button className="buttMain">Request a Demo</button>
</a>
</div>

<div className="MidTextDiv ">
{/* <text className="TopTextMiddle">$5T Sustainable Economy Comes With Massive Risks</text> */}
</div>
<img className="MidImage" src={img11}/>

<div className="MidTextDiv">
<text className="TopTextMiddle">Unprecedented Losses, Once-in-a-century Opportunities</text>
</div>
<img className="MidImage" src={img4}/>

  {/* <div className="MidTextDiv">
  <text className="TopTextMiddle">Developing Our Solutions With European Leaders</text>
  </div>
  <img className="MidImage" src={img5}/> */}


  <div className="MidTextDiv">
  <text className="TopTextMiddle">Solving The Crux Of The Problem</text>
  </div>
  <img className="MidImage" src={img3}/>


  {/* <img className="MidImage" src={img12}/> */}


<div className="MidTextDiv">
            <text className="TopTextMiddle"> Assisting The Most Climate-sensitive Industries</text>
            </div>
            {/* <Cards4/> */}
            <img className="MidImage" src={img14}/>

<div className="MidTextDiv">
  <text className="TopTextMiddle">Executive Board</text>
  </div>
  <img className="MidImage" src={img10}/>
  <img className="MidImage" src={img13}/>
</div>
</div>
 
      );
    }
}
export default SimpleNav;
