import React, { Component} from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./card.css";
import "./cards2.css";
import Blog from './blog';
import img3 from '../assets/ftiger/gh.jpeg';
import FooterPage from './footerv2';
import { Helmet } from 'react-helmet';

const TITLE = 'SauronEye | Which Edible Plants Could Thrive on Mars?';

const Blog4 = props =>{
    return(
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
        <div>

        <div>
         <Blog 
         title="Which Edible Plants Could Thrive on Mars?"
          imgsrc={img3}
            text="What do kale, carrots, lettuce, sweet potatoes, onions, dandelions, and hops have in common? They could all potentially be grown in Martian soil by future colonists, according to a recent project by a class of astrobiology students and their professor. In a new addition to the class, the students tried growing a variety of vegetables in simulated Martian soil to discover which edible species may be prospects for future colonists.

“The project combined my research—astrobiology and Mars—and my hobby of growing things,” said Edward Guinan, a professor of astronomy and astrophysics at Villanova University in Villanova, Pa. Guinan developed the Red Thumbs Mars Garden Project for his annual undergraduate-level astrobiology class and supervised the experiments, which concluded last month. The students, Guinan said, were very enthusiastic about growing their own Martian vegetables.
Kale, sweet potatoes, certain lettuces, and hops grew very easily, tasting no different than their terrestrial counterparts."/>
            
         </div>
        </div>

        <div className='py-0' >
        <FooterPage/>
        </div>

            </React.StrictMode>
    );
}  

export default Blog4;