
import React from'react';
import "./card3.css"
import Swiper2 from './swiper2';
import './bootstrap.css';
import './cards2.css';
import {Link} from 'react-router-dom';

const Card3 = props =>{
    return(

        <div className='text-center shadow cardStore py-0'>
            
            <div className=''>
            <Swiper2 imgsrc1={props.imgsrcc1}/>
            </div>

                <div class= "float-left col py-2">

                    <h4 className='card3-title py-2'>{props.title}</h4>
                    <div class= "">
                    <p className='card3-par py-4 '> {props.paragragh}</p>
                    </div>

                </div>

            </div>


    );
}  

export default Card3;