import React, { Component } from 'react';
import Card3 from './card3';
import "./cards3.css";
import "./card.css";
import "./cards2.css";
import img1 from '../assets/ftiger/G1.jpeg';
import img2 from '../assets/ftiger/G2.jpeg';
import img3 from '../assets/ftiger/G3.jpeg';


class Cards4 extends Component {
    render() {
        return (

           <div className="cards-container">

                    <Card3 imgsrcc1={img1} title="Insurers & Reinsurers" paragragh="Climate change is a source of financial risk. Insurers are exposed to both transition and physical risks through their underwriting and investment activities."/>
                    <Card3 imgsrcc1={img2}  title="Public & Private Banks" paragragh="Banks act on two fronts: both to manage their own financial exposures and to help finance a green agenda, which will be critical to mitigate the impact of global warming."/>
                    <Card3 imgsrcc1={img3}  title="Agriculture and Forestry" paragragh="In agriculture, incorporating climate risk assessments leads to precise regulatory compliance, crop planning, and hedging against extreme weather events."/>

            </div>

        );
    }
}

export default Cards4;
