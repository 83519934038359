
import React from'react';
import "./card3.css"
import Swiper2 from './swiper2';
import './bootstrap.css';
import './cards2.css';
import {Link} from 'react-router-dom';


const CardInsight = props =>{
    return(
        <div className='row'>

        <div className='text-center shadow cardSmall px-0'>
            <div className='py-0 '>
            <img className='imageSpace' src={props.imgsrc}>
            </img>
            </div>
                <div class= " py-2 px-0">
                    <h4 className='cardsmall-title py-1'>{props.title}</h4>
                </div>
        </div>
                        <div class= " py-2 px-0">
                    <h4 className='cardInsight-par py-1'>{props.par}</h4>
                </div>
        </div>
    );
}  

export default CardInsight;