import React, { Component } from 'react';
import Card4 from './card4';
import Card3 from './card3';
import "./cards3.css";
import searchLogo from "../assets/searchLogo.jpg";
import "./card.css";
import "./cards2.css";
import img1 from '../assets/ftiger/C1.jpeg';
import img2 from '../assets/ftiger/C2.jpeg';
import img3 from '../assets/ftiger/C3.jpeg';
import img4 from '../assets/ftiger/C4.jpeg';
import img5 from '../assets/ftiger/C5.jpeg';
import img6 from '../assets/ftiger/C6.jpeg';
import img7 from '../assets/ftiger/C7.jpeg';
import { Helmet } from 'react-helmet';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom';
import FooterPage from './footerv2';
const TITLE = 'SauronEye | Blogs';

class Careers extends Component {
    render() {
        return (
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
            <div>
                <Navbar collapseOnSelect expand="lg" bg='white' variant="light" class="row" width="100%">
  <div class="collapse navbar-collapse col-sm-12  my-lg-0" id="navbarSupportedContent" >

    <Link to = '' class="navbar-brand" >
    <img src={searchLogo}
          width="180"
          height="35"
          alt="">
    </img>
    </Link>

    <Link to = '' class="navbar-brand col-sm-2" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '   ' class="navbar-brand col-sm-3" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '/Blog' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Blog</text>
    </Link>

    <Link to = '/Careers' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Careers</text>
    </Link>

    <a  href="https://2qdn8k34pw2.typeform.com/to/m9bsQPaZ" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Customers</text>
    </a>

    <a  href="https://2qdn8k34pw2.typeform.com/to/hs3lwHbp" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Investors </text>
    </a>

</div>

</Navbar>
<h4 className='blog-title1 py-2'>Careers</h4>

<div>

            <div className="cards-supercontainer">
            <div className="cards-container-blog">
                <Link to = '/Careers/Senior_AI_Product_Counsel'>
                    <Card4 imgsrcc1={img1} title="Senior AI Product Counsel, Team Manager" paragragh="We’re seeking an AI Policy Counsel to help navigate the increasing AI legislative and regulatory landscape in the US and abroad. We’re looking for a legal self-starter who is comfortable jumping into a broad range of AI regulatory issues that will help shape AI policy going forward and for which there is little precedent."/>
                </Link>
                <Link to = '/Careers/Business_Developer_Intern' >
                    <Card4 imgsrcc1={img3}  title="Generative AI Engineer, LLM & Natural Language Processing " paragragh=""/>
                 </Link>
                 <Link to = '/Careers/Business_Developer_Intern'>
                    <Card4 imgsrcc1={img2}  title="Business Developer Intern, Growth & Marketing" paragragh=""/>
                </Link>
            </div>
            </div>

            <div className="cards-supercontainer">
            <div className="cards-container-blog">
                <Link to = '/Careers/Business_Developer_Intern' >
                    <Card4 imgsrcc1={img7} title="Generative AI Engineer, Planning & Business Consultancy" paragragh=" "/>
                </Link>
                <Link to = '/Careers/AI_Engineer' >
                    <Card4 imgsrcc1={img6}  title="AI Product Counsel, Education & Research" paragragh=""/>
                 </Link>
                 <Link to = '/Careers/Business_Developer_Intern'>
                    <Card4 imgsrcc1={img5}  title="AI Product Counsel, Law & Legal Affairs" paragragh=""/>
                </Link>
            </div>
            </div>
        
            </div>

            </div>
            <FooterPage/>

</React.StrictMode>
        );
    }
}

export default Careers;