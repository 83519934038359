import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";


const FooterPage = () => {
  return (

    <MDBFooter bgColor='primary' className='text-white text-center text-lg-left' style={{ backgroundColor: '#21081a' }}>
      <MDBContainer className='p-3'>
        <MDBRow>
          <MDBCol lg='6' md='12' className='mb-4 mb-md-0'>
            <h5 className=' text-white'>About us</h5>

            <p className= 'text-white text-align'>
              KlimAI is the AI climate copilot for strategic decision making, headquartered in the Netherlands.
            </p>
          </MDBCol>

          <MDBCol lg='6' md='12' className='mb-4 mb-md-0'>
            <h5 className='mb-0'>Contact</h5>
            <p className= 'text-white'> hello@klimai.com </p>
          </MDBCol>

        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-black'>
          KlimAI Inc.
        </a>
      </div>
    </MDBFooter>

  );
}

export default FooterPage;