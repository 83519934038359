import React, { Component } from 'react';
import Card3 from './card3';
import "./cards3.css";
import searchLogo from "../assets//ftiger/greenhouseLogo.jpeg";
import "./card.css";
import "./cards2.css";
import img1 from '../assets/ftiger/energy.jpeg';
import img2 from '../assets/ftiger/logistics.jpeg';
import img3 from '../assets/ftiger/inventory.jpeg';
import img4 from '../assets/ftiger/resources.jpeg';
import img5 from '../assets/ftiger/planning.jpeg';
import img6 from '../assets/ftiger/yield.jpeg';
import img7 from '../assets/ftiger/bottle.jpeg';
import img8 from '../assets/ftiger/KPI.jpeg';
import img9 from '../assets/ftiger/market.jpeg';
import img10 from '../assets/ftiger/campaign.jpeg';
import img11 from '../assets/ftiger/budget.jpeg';
import img12 from '../assets/ftiger/revenue.jpeg';
import img13 from '../assets/ftiger/cost.jpeg';
import img14 from '../assets/ftiger/customer.jpeg';
import img15 from '../assets/ftiger/performance.jpeg';
import img16 from '../assets/ftiger/plant.jpeg';
import img17 from '../assets/ftiger/insight2.jpeg';
import img18 from '../assets/ftiger/insight3.jpeg';
import { Helmet } from 'react-helmet';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom';
import FooterPage from './footerv2';
import CardSmall from './cardSmall';
import CardInsight from './cardInsight';
<script src="https://accounts.google.com/gsi/client" async></script>

const TITLE = 'SauronEye AI | Advanced AI for Operation Intelligence';

class Gplatform extends Component {
    render() {
        return (
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
            <div>
                <Navbar collapseOnSelect expand="lg" bg='white' variant="light" class="row" width="100%">
  <div class="collapse navbar-collapse col-sm-12  my-lg-0" id="navbarSupportedContent" >

    <Link to = '' class="navbar-brand" >
    <img src={searchLogo}
          width="440"
          height="35"
          alt="">
    </img>
    </Link>

    <Link to = '' class="navbar-brand col-sm-6" >
    <text class="txt-menu"></text>
    </Link>

    <Link to = '' class="navbar-brand col-sm-1" >
    <text class="text-menu">Profile</text>
    </Link>

    <Link to = '' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Login</text>
    </Link>

</div>

</Navbar>

<div className="row py-4">

                    <div className="col col-sm-7">
                    
                    <h4 className='gplat-title1 py-0'>Supply Chain</h4>
                    <div className="gplat-container col">
                        <Link to = '' >
                            <CardSmall imgsrc={img1} title="Energy" paragragh=" "/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img2}  title="Logistics" paragragh=""/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img3}  title="inventory" paragragh=""/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img4}  title="Resources" paragragh=""/>
                        </Link>
                    </div>


                    <h4 className='gplat-title1 py-0'>Plant Planning & Growth</h4>
                    <div className="gplat-container col">
                        <Link to = '' >
                            <CardSmall imgsrc={img5} title="Plannig" paragragh=" "/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img6}  title="Yield" paragragh=""/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img7}  title="Bottlenecks" paragragh=""/>
                        </Link>
                    </div>

                    <h4 className='gplat-title1 py-0'>Marketing</h4>
                    <div className="gplat-container col">
                        <Link to = '' >
                            <CardSmall imgsrc={img8} title="KPIs" paragragh=" "/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img9}  title="Markets" paragragh=""/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img10}  title="Campaigns" paragragh=""/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img11}  title="Budget" paragragh=""/>
                        </Link>
                    </div>

                                <h4 className='gplat-title1 py-0'>Sales & Performance</h4>
                    <div className="gplat-container col">
                        <Link to = '' >
                            <CardSmall imgsrc={img12} title="Revenue" paragragh=" "/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img13}  title="Costs" paragragh=""/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img14} title="Customers" paragragh=" "/>
                        </Link>
                        <Link to = '' >
                            <CardSmall imgsrc={img15}  title="Performance" paragragh=""/>
                        </Link>
                    </div>

                    </div>



            <div className="col">
            <h4 className='insight-title py-0'>What's new?</h4>
                <div className="gplat-container row">
                        <div className="py-2">
                        <Link to = '' >
                            <CardInsight imgsrc={img16} title="Trending Plant" par="Bambino price is up 20% this month!"/>
                        </Link>
                        </div>
                        <div className="py-4">
                        <Link to = '' >
                            <CardInsight imgsrc={img17} title="Energy Price Cooling" par="Gas prices to go down in Q4 2023!"/>
                        </Link>
                        </div>
                        <div className="py-2">
                        <Link to = '' >
                            <CardInsight imgsrc={img18} title="Demand Surge" par="Low supply for Chrysanthemums!"/>
                        </Link>
                        </div>
                            </div>

            </div>

            </div>

            </div>


{/* <div id="g_id_onload"
     data-client_id="AIzaSyDhDpGxpvp6ztFU64tmA_PL0Q4gHtack2k"
     data-context="signin"
     data-ux_mode="popup"
     data-login_uri="https://greenhouse.sauroneye.com/"
     data-auto_select="true"
     data-itp_support="true">
</div>

<div class="g_id_signin"
     data-type="standard"
     data-shape="rectangular"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
</div> */}



                    <FooterPage/>
                    </React.StrictMode>
        );
    }
}

export default Gplatform;