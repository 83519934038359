import React, { Component} from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./card.css";
import "./cards2.css";
import Blog from './blog';
import img3 from '../assets/ftiger/gr.jpeg';
import FooterPage from './footerv2';
import { Helmet } from 'react-helmet';

const TITLE = 'SauronEye | Germans Aim To Send Lettuce To Mars';

const Blog5 = props =>{
    return(
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
        <div>
        <div>
         <Blog 
         title="Germans Aim To Send Lettuce To Mars"
          imgsrc={img3}
            text="Lettuce on the moon might sound like the name of a prog-rock song, but that’s the eventual plan. This morning, Germany’s Aerospace Centre DLR announced that their experimental Antarctic greenhouse had harvested its first crops: 8 pounds of salad greens, 18 cucumbers, and 70 radishes, as reported by the Associated Press. It’s the first result of a project begun in January, when the greenhouse arrived in Antarctica, but there promises to be more to come. In addition to the veggies they just harvested, the scientists also planted strawberries, bell peppers, and a number of herbs. By May, DLR says they expect to be able to get about 10 pounds of produce per week.
            Growing food in one of Earth’s most inhospitable environs is a task in its own right, but the hope is that this experimental greenhouse, which is part of the EDEN ISS project, will provide insights into how to grow vegetables in space—and on alien worlds. Long term space voyages, such as a journey to Mars, will require a renewable food source, as would any colonies we built there. That means figuring out how to grow food in the specialized environment of a spaceship, but it might also have impacts here on Earth. As we’ve reported before, food production here on the small blue marble isn’t that stable right now, either. In the future, it’s likely agriculture on Earth will become more decoupled from the land because of climate change and population growth, and being able to cultivate crops in a contained environment—one that even recycles water from the air back into the plants’ nutrition system—could prove valuable.

Last month, NASA reported that the crew of the International Space Station are also conducting space veggie experiments, having just harvested three greens: mizuna, also known as “spider mustard,” red romaine lettuce, and tokyo bekana cabbage. AP reports that DLR spokesperson Daniel Schubert says “the Antarctic project aims to produce a wider range of vegetables that might one day be grown on Mars or the Moon.”"/>
            
         </div>
        </div>

        <div className='py-0' >
        <FooterPage/>
        </div>

            </React.StrictMode>
    );
}  

export default Blog5;