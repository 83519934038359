import React, { Component, useEffect, useState } from 'react';
// import './buttstrap.css';
import './bootstrap.css';
import SimpleNav from './SimpleNav';
import FooterPage from './footerv2';
import Cards4 from './cards4';
import { Helmet } from 'react-helmet';

const TITLE = 'KlimAI | AI Climate Copilot';

function Home() {
    const [data, setData] = useState([]);

    useEffect(() => {
      fetch('/api/data')
        .then(response => response.json())
        .then(data => setData(data));
    }, []);
     {
        return (
            <React.StrictMode>  
            <Helmet>
            <title>{ TITLE }</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
            </Helmet>
            <SimpleNav/>
            <FooterPage />
            </React.StrictMode>
            );
    }
}
export default Home;