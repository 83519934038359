import React from'react';
import "./responsive.css"
import Swiper2 from './swiper2';
import './bootstrap.css';
import {Link} from 'react-router-dom';


const Card4 = props =>{
    return(

        <div className='cardStore4'>
            
            <div className=''>
            <Swiper2 imgsrc1={props.imgsrcc1}/>
            </div>
                <div class= "float-left col py-2">
                    <h4 className='card4-title py-2'>{props.title}</h4>
                </div>

            </div>

    );
}  

export default Card4;