import React, { Component} from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./card.css";
import "./cards2.css";
import Blog from './blog';
import img3 from '../assets/ftiger/st.jpeg';
import FooterPage from './footerv2';
import { Helmet } from 'react-helmet';

const TITLE = 'SauronEye | Nertherlands Is The Worlds Second Biggest Agrifood Exporter';

const Blog6 = props =>{
    return(
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
        <div>

        <div>
         <Blog 
         title="Nertherlands Is The World's Second Biggest Agrifood Exporter"
          imgsrc={img3}
            text="Ad van Adrichem, general manager for Duijvestijn Tomatoes, explains: “Holland is pretty crowded. Our land is quite expensive and labour is expensive, so we have to be more efficient than others to compete. And that competition drives innovation and technology.”

Duijvestijn Tomatoes is an example of sustainable, innovative agriculture. Since 2011, it has been using geothermal energy to heat its greenhouses, and the plants grow in a hydroponic system to use less water.

The tomatoes are grown in small bags of rockwool substrate, made from spinning together molten basaltic rock into fine fibres, which contains nutrients and allows the plants to soak up water even when moisture levels are low.
No pesticides are used and the farm pipes waste CO2 into the greenhouses from a local Shell oil refinery, which the plants need to grow, and which reduces the carbon dioxide being released into the atmosphere.

The greenhouse has a double glass roof to conserve heat as well as LED lights, which mean the plants can keep growing through the night.
All of this means the team at Duijvestijn can produce higher yields of tomatoes, in less space, using fewer resources.

“Our greenhouses cover an area of 14 hectares, and we produce around 100 million tomatoes a year,” says van Adrichem.

“The idea is we can steer everything very precisely. We use all the new techniques and all the innovations with the minimum impact on the environment."/>
            
         </div>
        </div>

        <div className='py-0' >
        <FooterPage/>
        </div>

            </React.StrictMode>
    );
}  

export default Blog6;