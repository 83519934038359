import React, { useEffect, useState } from 'react';
import './App1.css';
import Home from './comps/Home';
import Blog2 from './comps/blog2';
import Blog1 from './comps/blog1';
import BlogList from './comps/blogList';
import Careers from './comps/careers';
import Blog3 from './comps/blog3';
import Blog4 from './comps/blog4';
import Blog5 from './comps/blog5';
import Blog6 from './comps/blog6';
import Gplatform from './comps/gplatform';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          {/* <p>
          {data.map(item => (
          <div key={item.id}>{item.value}</div>
        ))}
          </p> */}
        <Route exact path='/'><Home/></Route>
        <Route exact path='/Blog'><BlogList/></Route>
        <Route exact path='/Blog/Dutch-Researchers-Aim-For-Fully-Automated-Greenhouses'><Blog3/></Route>
        <Route exact path='/Blog/Autonomous-Greenhouse-challenge-By-Wageningen-University'><Blog2/></Route>
        <Route exact path='/Blog/Nasa-mission-to-live-on-mars-with-autonomous-greenhouses'><Blog1/></Route>
        <Route exact path='/Blog/Which-Edible-Plants-Could-Thrive-On-Mars'><Blog4/></Route>
        <Route exact path='/Blog/Germans-Aim-To-Send-Lettuce-To-Mars'><Blog5/></Route>
        <Route exact path='/Blog/Nertherlands-Is-The-Worlds-Second-Biggest-Agrifood-Exporter'><Blog6/></Route>
        <Route exact path='/Careers'><Careers/></Route>
        <Route exact path='/Careers/Senior_AI_Product_Counsel'><Home/></Route>
        <Route exact path='/Careers/AI_Engineer'><Home/></Route>
        <Route exact path='/Careers/Business_Developer_Intern'><Home/></Route>
        <Route exact path='/Careers/AI_Engineer'><Home/></Route>
        <Route exact path='/Careers/Business_Developer_Intern'><Home/></Route>
        <Route exact path='/Greenhouse-platform'><Gplatform/></Route>
        </Switch>
        </div>
    </Router>
     
  );
}

export default App;
