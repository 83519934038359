import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from '../assets/pic/MRI.jpg';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";
import "./cards2.css"

// import required modules
import { Pagination } from "swiper";

const Swiper2 = props =>{
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide><img src={props.imgsrc1} alt="1" className='img33'/></SwiperSlide>
      </Swiper>
    </>
  );
}

export default Swiper2;


