import React, { Component} from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./card.css";
import "./cards2.css";
import "./responsive.css"


const Blog = props =>{
    return(

        <div>
                <Navbar collapseOnSelect expand="lg" bg='white' variant="light" class="row" width="100%">
                <div class="collapse navbar-collapse col-sm-12  my-lg-0" id="navbarSupportedContent" >

                    <Link to = '' class="navbar-brand" >
                    <img src={searchLogo}
                        width="180"
                        height="35"
                        alt="">
                    </img>
                    </Link>

                    <Link to = '' class="navbar-brand col-sm-2" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '   ' class="navbar-brand col-sm-3" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '/Blog' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Blog</text>
    </Link>

    <Link to = '/Careers' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Careers</text>
    </Link>

    <a  href="https://2qdn8k34pw2.typeform.com/to/m9bsQPaZ" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Customers</text>
    </a>

    <a  href="https://2qdn8k34pw2.typeform.com/to/hs3lwHbp" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Investors </text>
    </a>

                </div>

                </Navbar>

                <div class= "blog-body col py-2">

                    <h4 className='blog-title py-2'>{props.title}</h4>
                    <img className="blog-img" src={props.imgsrc}/>
                    <div class= "">
                    <p className='blog-par py-4 '>{props.text}</p>

                </div>

            </div>

            </div>
    );

}  

export default Blog;