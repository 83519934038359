import React, { Component } from 'react';
import Card3 from './card3';
import "./cards3.css";
import "./card.css";
import "./cards2.css";
import img1 from '../assets/ftiger/Z1.jpeg';
import img2 from '../assets/ftiger/Z2.jpeg';
import img3 from '../assets/ftiger/Z3.jpeg';

class Cards3 extends Component {
    render() {
        return (
            <div className="cards-supercontainer">

           <div className="cards-container">

                    <Card3 imgsrcc1={img1} title="Market & Competitor Intelligence" paragragh="Analyzing real-time market & competitor data and your existing sauroneye-integrated data, we plan for product pricing aiming industry-leading profit margins."/>
                    <Card3 imgsrcc1={img2}  title="Operation Monitoring & Insights" paragragh="Our operational AI technology reveals deep hidden insights about your productions, system failures, delayed orders, logistics and more."/>
                    <Card3 imgsrcc1={img3}  title="Precise Business Intelligence" paragragh="With generative AI we provide human-level business insights to improve profit margin, integrate vertically, enter new markets, scale productions and more."/>

            </div>
            </div>

        );
    }
}

export default Cards3;
