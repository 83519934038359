import React, { Component } from 'react';
import Card4 from './card4';
import Card3 from './card3';
import "./cards3.css";
import searchLogo from "../assets/searchLogo.jpg";
import "./card.css";
import "./cards2.css";
import img1 from '../assets/ftiger/tom.jpeg';
import img2 from '../assets/ftiger/wag.jpeg';
import img3 from '../assets/ftiger/astro.jpeg';
import img4 from '../assets/ftiger/gh.jpeg';
import img5 from '../assets/ftiger/gr.jpeg';
import img6 from '../assets/ftiger/st.jpeg';
import { Helmet } from 'react-helmet';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom';
import FooterPage from './footerv2';
const TITLE = 'SauronEye | Blogs';

class BlogList extends Component {
    render() {
        return (
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
            <div>
                <Navbar collapseOnSelect expand="lg" bg='white' variant="light" class="row" width="100%">
  <div class="collapse navbar-collapse col-sm-12  my-lg-0" id="navbarSupportedContent" >

    <Link to = '' class="navbar-brand" >
    <img src={searchLogo}
          width="180"
          height="35"
          alt="">
    </img>
    </Link>

    <Link to = '' class="navbar-brand col-sm-2" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '   ' class="navbar-brand col-sm-3" >
    <text class="txt-menu-bar"></text>
    </Link>

    <Link to = '/Blog' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Blog</text>
    </Link>

    <Link to = '/Careers' class="navbar-brand col-sm-1" >
    <text class="text-menu" >Careers</text>
    </Link>

    <a  href="https://2qdn8k34pw2.typeform.com/to/m9bsQPaZ" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Customers</text>
    </a>

    <a  href="https://2qdn8k34pw2.typeform.com/to/hs3lwHbp" class="navbar-brand col-sm-1" >
    <text class="text-menu" >Investors </text>
    </a>

</div>

</Navbar>
<h4 className='blog-title1 py-2'>Blog</h4>

<div>

            <div className="cards-supercontainer">
            <div className="cards-container-blog">
                <Link to = '/Blog/Dutch-Researchers-Aim-For-Fully-Automated-Greenhouses' >
                    <Card4 imgsrcc1={img1} title="Dutch researchers aim for fully automated greenhouses" paragragh=" "/>
                </Link>
                <Link to = '/Blog/Autonomous-Greenhouse-challenge-by-Wageningen-university' >
                    <Card4 imgsrcc1={img2}  title="Autonomous greenhouse challenge by Wageningen university" paragragh=""/>
                 </Link>
                 <Link to = '/Blog/Nasa-mission-to-live-on-mars-with-autonomous-greenhouses' >
                    <Card4 imgsrcc1={img3}  title="NASA aims at AI-ready greenhouses for food supply on mars" paragragh=""/>
                </Link>
            </div>
            </div>
            
            <div className="cards-supercontainer">         
            <div className="cards-container-blog">
                <Link to = '/Blog/Which-Edible-Plants-Could-Thrive-On-Mars' >
                    <Card4 imgsrcc1={img4} title="Which edible plants could thrive on mars?" paragragh=""/>
                </Link>
                <Link to = '/Blog/Germans-Aim-To-Send-Lettuce-To-Mars' >
                    <Card4 imgsrcc1={img2}  title="Germans aim to send lettuce to mars" paragragh=""/>
                 </Link>
                 <Link to = '/Blog/Nertherlands-Is-The-Worlds-Second-Biggest-Agrifood-Exporter' >
                    <Card4 imgsrcc1={img6}  title="Nertherlands is the worlds second biggest agrifood exporter" paragragh=""/>
                </Link>
            </div>
            </div>
            </div>

            </div>
            <FooterPage/>

</React.StrictMode>
        );
    }
}

export default BlogList;