import React, { Component} from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./card.css";
import "./cards2.css";
import Blog from './blog';
import img3 from '../assets/ftiger/astro.jpeg';
import FooterPage from './footerv2';
import { Helmet } from 'react-helmet';

const TITLE = 'SauronEye | NASA aims at AI-ready greenhouses for food supply on Mars';

const Blog1 = props =>{
    return(
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
        <div>

        <div>
         <Blog 
         title="NASA's mission to live on Mars is only possible with AI-ready greenhouses!"
          imgsrc={img3}
            text="Mars is different; no mass fertile lands, and not a rich atmosphere for vegetables to grow. With an atmosphere volume less than 1% of Earth's many believe it is impossible to grow vegetables and feed our first colonies -- or even breathe. but it is not end of story.
            Mars's thin air composition is full of CO2 which is central for agriculture; and consuming CO2 releases O2! By capitalising on this excess CO2 we can hit two targets with one stone: to feed, and to provide first colonies with fresh oxygen-rich air.
            Our goal at Sauroneye AI is to automate greenhouse operations in a way they work standalone and do what we want 10x more efficient and more sustainable than what we could.
            Looking back to Earth, according to World Food Programme we still have famines, and food supplies are crucial for day to day life of many people in Central America, Africa, Syria, Yemen and Afghanistan. Let see how we can make our dream happen for a world without food scarcity on Earth and Mars!"/>
            
         </div>
        </div>

        <div className='py-0' >
        <FooterPage/>
        </div>

            </React.StrictMode>
    );
}  

export default Blog1;