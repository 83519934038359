import React, { Component} from 'react';
import "./StickyNav.css";
import Navbar from 'react-bootstrap/Navbar';
import searchLogo from "../assets/searchLogo.jpg";
import {Link} from 'react-router-dom';
import "./cards2.css";
import "./card.css";
import "./cards2.css";
import Blog from './blog';
import img3 from '../assets/ftiger/tom.jpeg';
import FooterPage from './footerv2';
import { Helmet } from 'react-helmet';

const TITLE = 'SauronEye | Dutch Researchers Aim For Fully Automated Greenhouses';

const Blog3 = props =>{
    return(
            <React.StrictMode> 
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
        <div>

        <div>
         <Blog 
         title="Dutch Researchers At Wageningen University Aim For Fully Automated Greenhouses"
          imgsrc={img3}
            text="In greenhouses, many aspects of growing can already be controlled in various ways. So, what if you could develop a fully autonomous cultivation method that could be applied to any greenhouse, anywhere in the world? That’s the ambition of the WUR researchers working in the field of Autonomous Greenhouses.
            Our world’s growing population and changing climate press us to find the most efficient production methods for our crops. Greenhouse horticulture plays an important role in the year-round production of fresh and healthy fruit and vegetables with a consistently high quality. Cultivation in greenhouses must be efficient in the use of natural resources, economically viable, and produce a high quality product according to tight planning. However, the limiting factor is becoming the availability of sufficient highly qualified staff with knowledge to cultivate a high-quality product and who can oversee all aspects of an efficient production system with minimal use of resources.

We are working towards an autonomous greenhouse in which cultivation is controlled remotely via artificial intelligence, with the help of intelligent sensors and measurements of crop characteristics, and in which automatic systems handle crops to achieve a sustainable and profitable cultivation system."/>
            
         </div>
        </div>

        <div className='py-0' >
        <FooterPage/>
        </div>

            </React.StrictMode>
    );
}  

export default Blog3;